import type { AppProps } from 'next/app'
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter'

export default function App(props: AppProps) {
  return (
    <AppCacheProvider {...props}>
      <props.Component {...props.pageProps} />
    </AppCacheProvider>
  )
}
